<template>
  <div class="userout">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>人事服务外包</h3>
          <p>{{ $config.bmYears }}年人力资源行业经验</p>
          <p>邦芒HR 为企业用工降本增效控风险</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/userout_banner.jpg" />
    </div>

    <div class="pay_one">
      <div class="all_box">
        <div class="pay_one_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>
        <div class="pay_one_box">
          <div class="pay_one_body">
            <div class="userout_one_list">
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one01.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>人事代理服务</h3>
                  <p>
                    社会保险服务、住房公积金服务、薪酬服务、档案管理服务、人事手续服务、咨询管理服务、外国人居留及就业证办理服务
                  </p>
                </div>
              </div>
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one02.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>商业险服务</h3>
                  <p>
                    雇主责任险、员工福利险、人身意外险、补充医疗险、高端医疗险
                  </p>
                </div>
              </div>
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one03.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>劳务派遣服务</h3>
                  <p>
                    入职/离职手续办理、员工档案管理、社会保险服务、工伤、劳资纠纷处理、招聘服务、体检服务、培训与员工发展、薪酬管理服务
                  </p>
                </div>
              </div>
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one04.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>健康管理服务</h3>
                  <p>
                    入职体检、职业病体检、企业年度体检、个性化定制体检
                  </p>
                </div>
              </div>
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one05.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>薪酬服务</h3>
                  <p>
                    薪资核算服务、税务代理、薪资电子化支付、薪资和工资单交付、标准化及企业化报表服务
                  </p>
                </div>
              </div>
              <div class="userout_one_item">
                <div class="userout_one_item_left">
                  <img src="@/assets/userout_one06.png" />
                </div>
                <div class="userout_one_item_right">
                  <h3>企业福利平台</h3>
                  <p>
                    主打全球化集采优势，为企业提供全线福利服务。主营产品有:橙意福豆商城、年节关怀福利、健康管理、卡券定制等。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="henfu_po">
      <henfu></henfu>
    </div>

    <div class="userout_two">
      <div class="all_box">
        <div class="userout_two_box">
          <div class="userout_two_title">
            <textTitle
              :cnTitle="txt.twoCnTitle"
              :enTitle="txt.twoEnTitle"
            ></textTitle>
          </div>
          <div class="userout_two_list">
            <div class="userout_two_item">
              <img src="@/assets/userout01.png" />
              <p>有效控制职业风险，充分维护客户利益，保护人才</p>
            </div>
            <div class="userout_two_item">
              <img src="@/assets/userout02.png" />
              <p>定制个性化解决方案</p>
            </div>
            <div class="userout_two_item">
              <img src="@/assets/userout03.png" />
              <p>简化招聘流程及人事管理</p>
            </div>
            <div class="userout_two_item">
              <img src="@/assets/userout04.png" />
              <p>有效控制运营成本，实现高效运作和服务</p>
            </div>
            <div class="userout_two_item">
              <img src="@/assets/userout05.png" />
              <p>全国跨地区人力资源外包</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <check :type="2" @gotoContact="gotoContact($event)"></check>

    <foots @navChange = "navChange"></foots>
  </div>
</template>
<script>
import check from "@/components/check.vue";
import textTitle from "@/components/textTitle.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import henfu from "@/components/henfu.vue";
export default {
  name: "Human",
  components: {
    titles,
    foots,
    henfu,
    textTitle,
    check,
  },
  data() {
    return {
      txt: {
        oneCnTitle: "邦芒能提供的服务",
        oneEnTitle: "Provision of services",
        twoCnTitle: "5大优势 专业团队",
        twoEnTitle: "Professional team",
      },
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      window.scrollTo(0, 0);
    }
  },
};
</script>
<style lang="scss" scoped>
.userout_one_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 55px;
  box-sizing: border-box;
  .userout_one_item {
    width: 48%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 43px 0;
    .userout_one_item_right{
        width: 85%;
        text-align: left;
        h3{
            font-size: 18px;
            color: #b59167;
            font-weight: bold;
            margin: 0;
            margin-bottom: 15px;
        }
        p{
            margin: 0;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
        }
    }
  }
}
.userout_two {
  padding-bottom: 150px;
}
.userout_two_title {
  padding: 90px 0;
}
.userout_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .userout_two_item {
    width: 14%;
    p {
      line-height: 20px;
      font-size: 16px;
      color: #333333;
      padding-top: 45px;
      margin: 0;
    }
  }
}
.pay_one_title {
  padding: 100px 0;
}
.pay_one {
  background: #f2f2f2;
}
.pay_one_box {
  position: relative;
  height: 500px;
}
.pay_one_body {
  position: absolute;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 90px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #fff;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #fff;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}
</style>